export const PACKAGE = { name: '@mui/x-data-grid-pro', version: '7.23.0' };
export * from '@mui/x-data-grid-pro';
export { unstable_resetCleanupTracking as resetCleanupTracking } from '@mui/x-data-grid-pro';
export * as 'components' from '@mui/x-data-grid-pro/components/index.js';
export * as 'hooks' from '@mui/x-data-grid-pro/hooks/index.js';
export * as 'internals' from '@mui/x-data-grid-pro/internals/index.js';
export * as 'locales' from '@mui/x-data-grid-pro/locales.js';
export * as 'material' from '@mui/x-data-grid-pro/material/index.js';
export * as 'models' from '@mui/x-data-grid-pro/models/index.js';
export * as 'themeAugmentation' from '@mui/x-data-grid-pro/themeAugmentation/index.js';
export * as 'typeOverloads' from '@mui/x-data-grid-pro/typeOverloads/index.js';
export * as 'utils' from '@mui/x-data-grid-pro/utils/index.js';
import * as xDataGridPro from '@mui/x-data-grid-pro';

            const resolveImport = (itemName, subItemName) => {
                const item = xDataGridPro[itemName];
                const subItem = xDataGridPro[subItemName];
                if (item && subItem) {
                    try {
                        if (!item.hasOwnProperty(subItemName)) {
                            item[subItemName] = subItem;

                            const prefixes = [ 'internal_', 'unstable_' ];
                            for (let prefix of prefixes) {
                                if (subItemName.startsWith(prefix)) {
                                    const newName = subItemName.slice(prefix.length);
                                    item[newName] = subItem;
                                }
                            }
                        }
                    } catch {}
                }
            };
        
resolveImport('DataGridPro', 'DATA_GRID_PRO_PROPS_DEFAULT_VALUES');
resolveImport('DataGridPro', 'DataGrid');
resolveImport('DataGridPro', 'DataGridPremium');
resolveImport('DataGridPro', 'DataGridPro');