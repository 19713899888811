export const PACKAGE = { name: '@mui/x-license', version: '7.23.0' };
export * from '@mui/x-license';
export * as 'utils' from '@mui/x-license/utils/index.js';
import * as xLicense from '@mui/x-license';

            const resolveImport = (itemName, subItemName) => {
                const item = xLicense[itemName];
                const subItem = xLicense[subItemName];
                if (item && subItem) {
                    try {
                        if (!item.hasOwnProperty(subItemName)) {
                            item[subItemName] = subItem;

                            const prefixes = [ 'internal_', 'unstable_' ];
                            for (let prefix of prefixes) {
                                if (subItemName.startsWith(prefix)) {
                                    const newName = subItemName.slice(prefix.length);
                                    item[newName] = subItem;
                                }
                            }
                        }
                    } catch {}
                }
            };
        
resolveImport('generateLicense', 'generateLicense');
resolveImport('verifyLicense', 'generateReleaseInfo');
resolveImport('verifyLicense', 'verifyLicense');
resolveImport('useLicenseVerifier', 'useLicenseVerifier');
resolveImport('Watermark', 'Watermark');
resolveImport('Unstable_LicenseInfoProvider', 'Unstable_LicenseInfoProvider');