export const PACKAGE = { name: '@mui/x-data-grid-premium', version: '7.23.0' };
export * from '@mui/x-data-grid-premium';
export { unstable_resetCleanupTracking as resetCleanupTracking } from '@mui/x-data-grid-premium';
export * as 'components' from '@mui/x-data-grid-premium/components/index.js';
export * as 'hooks' from '@mui/x-data-grid-premium/hooks/index.js';
export * as 'locales' from '@mui/x-data-grid-premium/locales.js';
export * as 'material' from '@mui/x-data-grid-premium/material/index.js';
export * as 'models' from '@mui/x-data-grid-premium/models/index.js';
export * as 'themeAugmentation' from '@mui/x-data-grid-premium/themeAugmentation/index.js';
export * as 'typeOverloads' from '@mui/x-data-grid-premium/typeOverloads/index.js';
import * as xDataGridPremium from '@mui/x-data-grid-premium';

            const resolveImport = (itemName, subItemName) => {
                const item = xDataGridPremium[itemName];
                const subItem = xDataGridPremium[subItemName];
                if (item && subItem) {
                    try {
                        if (!item.hasOwnProperty(subItemName)) {
                            item[subItemName] = subItem;

                            const prefixes = [ 'internal_', 'unstable_' ];
                            for (let prefix of prefixes) {
                                if (subItemName.startsWith(prefix)) {
                                    const newName = subItemName.slice(prefix.length);
                                    item[newName] = subItem;
                                }
                            }
                        }
                    } catch {}
                }
            };
        
resolveImport('DataGridPremium', 'DATA_GRID_PREMIUM_PROPS_DEFAULT_VALUES');
resolveImport('DataGridPremium', 'DataGrid');
resolveImport('DataGridPremium', 'DataGridPro');
resolveImport('DataGridPremium', 'DataGridPremium');