export const PACKAGE = { name: '@mui/x-date-pickers-pro', version: '7.23.0' };
export * from '@mui/x-date-pickers-pro';
export { unstable_useDateField as useDateField } from '@mui/x-date-pickers-pro';
export { unstable_useTimeField as useTimeField } from '@mui/x-date-pickers-pro';
export { unstable_useDateTimeField as useDateTimeField } from '@mui/x-date-pickers-pro';
export { unstable_useMultiInputDateRangeField as useMultiInputDateRangeField } from '@mui/x-date-pickers-pro';
export { unstable_useMultiInputTimeRangeField as useMultiInputTimeRangeField } from '@mui/x-date-pickers-pro';
export { unstable_useMultiInputDateTimeRangeField as useMultiInputDateTimeRangeField } from '@mui/x-date-pickers-pro';
export { unstable_useSingleInputDateRangeField as useSingleInputDateRangeField } from '@mui/x-date-pickers-pro';
export { unstable_useSingleInputTimeRangeField as useSingleInputTimeRangeField } from '@mui/x-date-pickers-pro';
export { unstable_useSingleInputDateTimeRangeField as useSingleInputDateTimeRangeField } from '@mui/x-date-pickers-pro';
export * as 'AdapterDayjs' from '@mui/x-date-pickers-pro/AdapterDayjs/index.js';
export * as 'dateRangeViewRenderers' from '@mui/x-date-pickers-pro/dateRangeViewRenderers/index.js';
export * as 'locales' from '@mui/x-date-pickers-pro/locales/index.js';
export * as 'models' from '@mui/x-date-pickers-pro/models/index.js';
export * as 'themeAugmentation' from '@mui/x-date-pickers-pro/themeAugmentation/index.js';
export * as 'validation' from '@mui/x-date-pickers-pro/validation/index.js';
import * as xDatePickersPro from '@mui/x-date-pickers-pro';

            const resolveImport = (itemName, subItemName) => {
                const item = xDatePickersPro[itemName];
                const subItem = xDatePickersPro[subItemName];
                if (item && subItem) {
                    try {
                        if (!item.hasOwnProperty(subItemName)) {
                            item[subItemName] = subItem;

                            const prefixes = [ 'internal_', 'unstable_' ];
                            for (let prefix of prefixes) {
                                if (subItemName.startsWith(prefix)) {
                                    const newName = subItemName.slice(prefix.length);
                                    item[newName] = subItem;
                                }
                            }
                        }
                    } catch {}
                }
            };
        
resolveImport('LocalizationProvider', 'LocalizationProvider');
resolveImport('DateRangePickerDay', 'DateRangePickerDay');
resolveImport('DateRangePickerDay', 'dateRangePickerDayClasses');
resolveImport('DateRangePickerDay', 'getDateRangePickerDayUtilityClass');
resolveImport('MultiInputDateRangeField', 'MultiInputDateRangeField');
resolveImport('MultiInputDateRangeField', 'getMultiInputDateRangeFieldUtilityClass');
resolveImport('MultiInputDateRangeField', 'multiInputDateRangeFieldClasses');
resolveImport('MultiInputDateRangeField', 'unstable_useMultiInputDateRangeField');
resolveImport('MultiInputTimeRangeField', 'MultiInputTimeRangeField');
resolveImport('MultiInputTimeRangeField', 'getMultiInputTimeRangeFieldUtilityClass');
resolveImport('MultiInputTimeRangeField', 'multiInputTimeRangeFieldClasses');
resolveImport('MultiInputTimeRangeField', 'unstable_useMultiInputTimeRangeField');
resolveImport('MultiInputDateTimeRangeField', 'MultiInputDateTimeRangeField');
resolveImport('MultiInputDateTimeRangeField', 'getMultiInputDateTimeRangeFieldUtilityClass');
resolveImport('MultiInputDateTimeRangeField', 'multiInputDateTimeRangeFieldClasses');
resolveImport('MultiInputDateTimeRangeField', 'unstable_useMultiInputDateTimeRangeField');
resolveImport('SingleInputDateRangeField', 'SingleInputDateRangeField');
resolveImport('SingleInputDateRangeField', 'unstable_useSingleInputDateRangeField');
resolveImport('SingleInputTimeRangeField', 'SingleInputTimeRangeField');
resolveImport('SingleInputTimeRangeField', 'unstable_useSingleInputTimeRangeField');
resolveImport('SingleInputDateTimeRangeField', 'SingleInputDateTimeRangeField');
resolveImport('SingleInputDateTimeRangeField', 'unstable_useSingleInputDateTimeRangeField');
resolveImport('DateRangeCalendar', 'DateRangeCalendar');
resolveImport('DateRangeCalendar', 'dateRangeCalendarClasses');
resolveImport('DateRangeCalendar', 'getDateRangeCalendarUtilityClass');
resolveImport('PickersRangeCalendarHeader', 'PickersRangeCalendarHeader');
resolveImport('DateRangePicker', 'DateRangePicker');
resolveImport('DateRangePicker', 'DateRangePickerToolbar');
resolveImport('DateRangePicker', 'dateRangePickerToolbarClasses');
resolveImport('DateRangePicker', 'getDateRangePickerToolbarUtilityClass');
resolveImport('DesktopDateRangePicker', 'DesktopDateRangePicker');
resolveImport('MobileDateRangePicker', 'MobileDateRangePicker');
resolveImport('StaticDateRangePicker', 'StaticDateRangePicker');
resolveImport('DateTimeRangePicker', 'DateTimeRangePicker');
resolveImport('DateTimeRangePicker', 'DateTimeRangePickerTabs');
resolveImport('DateTimeRangePicker', 'DateTimeRangePickerToolbar');
resolveImport('DateTimeRangePicker', 'dateTimeRangePickerTabsClasses');
resolveImport('DateTimeRangePicker', 'dateTimeRangePickerToolbarClasses');
resolveImport('DateTimeRangePicker', 'getDateTimeRangePickerTabsUtilityClass');
resolveImport('DateTimeRangePicker', 'getDateTimeRangePickerToolbarUtilityClass');
resolveImport('DesktopDateTimeRangePicker', 'DesktopDateTimeRangePicker');
resolveImport('MobileDateTimeRangePicker', 'MobileDateTimeRangePicker');