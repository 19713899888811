export const PACKAGE = { name: '@mui/x-data-grid', version: '7.23.0' };
export * from '@mui/x-data-grid';
export { unstable_resetCleanupTracking as resetCleanupTracking } from '@mui/x-data-grid';
export * as 'colDef' from '@mui/x-data-grid/colDef/index.js';
export * as 'components' from '@mui/x-data-grid/components/index.js';
export * as 'constants' from '@mui/x-data-grid/constants/index.js';
export * as 'context' from '@mui/x-data-grid/context/index.js';
export * as 'hooks' from '@mui/x-data-grid/hooks/index.js';
export * as 'internals' from '@mui/x-data-grid/internals/index.js';
export * as 'locales' from '@mui/x-data-grid/locales/index.js';
export * as 'material' from '@mui/x-data-grid/material/index.js';
export * as 'models' from '@mui/x-data-grid/models/index.js';
export * as 'themeAugmentation' from '@mui/x-data-grid/themeAugmentation/index.js';
export * as 'utils' from '@mui/x-data-grid/utils/index.js';
import * as xDataGrid from '@mui/x-data-grid';

            const resolveImport = (itemName, subItemName) => {
                const item = xDataGrid[itemName];
                const subItem = xDataGrid[subItemName];
                if (item && subItem) {
                    try {
                        if (!item.hasOwnProperty(subItemName)) {
                            item[subItemName] = subItem;

                            const prefixes = [ 'internal_', 'unstable_' ];
                            for (let prefix of prefixes) {
                                if (subItemName.startsWith(prefix)) {
                                    const newName = subItemName.slice(prefix.length);
                                    item[newName] = subItem;
                                }
                            }
                        }
                    } catch {}
                }
            };
        
resolveImport('DataGrid', 'DataGrid');