export const PACKAGE = { name: '@mui/x-internals', version: '7.23.0' };
export * from '@mui/x-internals';
export * as 'reactMajor' from '@mui/x-internals/reactMajor.js';
export * as 'slots' from '@mui/x-internals/slots/index.js';
export * as 'warning' from '@mui/x-internals/warning/index.js';
import * as xInternals from '@mui/x-internals';

            const resolveImport = (itemName, subItemName) => {
                const item = xInternals[itemName];
                const subItem = xInternals[subItemName];
                if (item && subItem) {
                    try {
                        if (!item.hasOwnProperty(subItemName)) {
                            item[subItemName] = subItem;

                            const prefixes = [ 'internal_', 'unstable_' ];
                            for (let prefix of prefixes) {
                                if (subItemName.startsWith(prefix)) {
                                    const newName = subItemName.slice(prefix.length);
                                    item[newName] = subItem;
                                }
                            }
                        }
                    } catch {}
                }
            };
        
resolveImport('EventManager', 'EventManager');
resolveImport('fastMemo', 'fastMemo');
resolveImport('fastObjectShallowCompare', 'fastObjectShallowCompare');
resolveImport('throttle', 'throttle');
resolveImport('useResizeObserver', 'useResizeObserver');