export const PACKAGE = { name: '@mui/x-charts', version: '7.23.0' };
export * from '@mui/x-charts';
export { unstable_useBarSeries as useBarSeries } from '@mui/x-charts';
export { unstable_useLineSeries as useLineSeries } from '@mui/x-charts';
export { unstable_usePieSeries as usePieSeries } from '@mui/x-charts';
export { unstable_useScatterSeries as useScatterSeries } from '@mui/x-charts';
export { unstable_useSeries as useSeries } from '@mui/x-charts';
export * as 'ChartsOverlay' from '@mui/x-charts/ChartsOverlay/index.js';
export * as 'colorPalettes' from '@mui/x-charts/colorPalettes/index.js';
export * as 'constants' from '@mui/x-charts/constants/index.js';
export * as 'context' from '@mui/x-charts/context/index.js';
export * as 'hooks' from '@mui/x-charts/hooks/index.js';
export * as 'internals' from '@mui/x-charts/internals/index.js';
export * as 'models' from '@mui/x-charts/models/index.js';
export * as 'themeAugmentation' from '@mui/x-charts/themeAugmentation/index.js';
import * as xCharts from '@mui/x-charts';

            const resolveImport = (itemName, subItemName) => {
                const item = xCharts[itemName];
                const subItem = xCharts[subItemName];
                if (item && subItem) {
                    try {
                        if (!item.hasOwnProperty(subItemName)) {
                            item[subItemName] = subItem;

                            const prefixes = [ 'internal_', 'unstable_' ];
                            for (let prefix of prefixes) {
                                if (subItemName.startsWith(prefix)) {
                                    const newName = subItemName.slice(prefix.length);
                                    item[newName] = subItem;
                                }
                            }
                        }
                    } catch {}
                }
            };
        
resolveImport('ChartsClipPath', 'ChartsClipPath');
resolveImport('ChartsReferenceLine', 'ChartsReferenceLine');
resolveImport('ChartsReferenceLine', 'getReferenceLineUtilityClass');
resolveImport('ChartsReferenceLine', 'referenceLineClasses');
resolveImport('ChartsAxis', 'ChartsAxis');
resolveImport('ChartsAxis', 'axisClasses');
resolveImport('ChartsAxis', 'getAxisUtilityClass');
resolveImport('ChartsXAxis', 'ChartsXAxis');
resolveImport('ChartsYAxis', 'ChartsYAxis');
resolveImport('ChartsGrid', 'ChartsGrid');
resolveImport('ChartsGrid', 'chartsGridClasses');
resolveImport('ChartsGrid', 'getChartsGridUtilityClass');
resolveImport('ChartsText', 'ChartsText');
resolveImport('ChartsTooltip', 'useMouseTracker');
resolveImport('ChartsTooltip', 'ChartsTooltip');
resolveImport('ChartsTooltip', 'chartsTooltipClasses');
resolveImport('ChartsTooltip', 'getChartsTooltipUtilityClass');
resolveImport('ChartsTooltip', 'ChartsAxisTooltipContent');
resolveImport('ChartsTooltip', 'ChartsItemTooltipContent');
resolveImport('ChartsTooltip', 'DefaultChartsAxisTooltipContent');
resolveImport('ChartsTooltip', 'DefaultChartsItemTooltipContent');
resolveImport('ChartsTooltip', 'ChartsTooltipCell');
resolveImport('ChartsTooltip', 'ChartsTooltipMark');
resolveImport('ChartsTooltip', 'ChartsTooltipPaper');
resolveImport('ChartsTooltip', 'ChartsTooltipRow');
resolveImport('ChartsTooltip', 'ChartsTooltipTable');
resolveImport('ChartsTooltip', 'useItemTooltip');
resolveImport('ChartsTooltip', 'useAxisTooltip');
resolveImport('ChartsLegend', 'ChartsLegend');
resolveImport('ChartsLegend', 'DefaultChartsLegend');
resolveImport('ChartsLegend', 'ContinuousColorLegend');
resolveImport('ChartsLegend', 'PiecewiseColorLegend');
resolveImport('ChartsLegend', 'getLegendUtilityClass');
resolveImport('ChartsLegend', 'legendClasses');
resolveImport('ChartsLegend', 'getSeriesToDisplay');
resolveImport('ChartsAxisHighlight', 'ChartsAxisHighlight');
resolveImport('ChartsAxisHighlight', 'ChartsAxisHighlightPath');
resolveImport('ChartsAxisHighlight', 'chartsAxisHighlightClasses');
resolveImport('ChartsAxisHighlight', 'getAxisHighlightUtilityClass');
resolveImport('ChartsVoronoiHandler', 'ChartsVoronoiHandler');
resolveImport('ChartsOnAxisClickHandler', 'ChartsOnAxisClickHandler');
resolveImport('BarChart', 'BarChart');
resolveImport('BarChart', 'BarPlot');
resolveImport('BarChart', 'BarElement');
resolveImport('BarChart', 'BarElementPath');
resolveImport('BarChart', 'barElementClasses');
resolveImport('BarChart', 'getBarElementUtilityClass');
resolveImport('BarChart', 'BarLabel');
resolveImport('BarChart', 'barLabelClasses');
resolveImport('BarChart', 'getBarLabelUtilityClass');
resolveImport('LineChart', 'getMarkElementUtilityClass');
resolveImport('LineChart', 'markElementClasses');
resolveImport('LineChart', 'LineChart');
resolveImport('LineChart', 'LinePlot');
resolveImport('LineChart', 'AreaPlot');
resolveImport('LineChart', 'MarkPlot');
resolveImport('LineChart', 'LineHighlightPlot');
resolveImport('LineChart', 'AreaElement');
resolveImport('LineChart', 'areaElementClasses');
resolveImport('LineChart', 'getAreaElementUtilityClass');
resolveImport('LineChart', 'AnimatedArea');
resolveImport('LineChart', 'AreaElementPath');
resolveImport('LineChart', 'LineElement');
resolveImport('LineChart', 'getLineElementUtilityClass');
resolveImport('LineChart', 'lineElementClasses');
resolveImport('LineChart', 'AnimatedLine');
resolveImport('LineChart', 'LineElementPath');
resolveImport('LineChart', 'MarkElement');
resolveImport('LineChart', 'LineHighlightElement');
resolveImport('LineChart', 'getHighlightElementUtilityClass');
resolveImport('LineChart', 'lineHighlightElementClasses');
resolveImport('PieChart', 'PieChart');
resolveImport('PieChart', 'PiePlot');
resolveImport('PieChart', 'PieArcPlot');
resolveImport('PieChart', 'PieArcLabelPlot');
resolveImport('PieChart', 'PieArc');
resolveImport('PieChart', 'getPieArcUtilityClass');
resolveImport('PieChart', 'pieArcClasses');
resolveImport('PieChart', 'PieArcLabel');
resolveImport('PieChart', 'getPieArcLabelUtilityClass');
resolveImport('PieChart', 'pieArcLabelClasses');
resolveImport('PieChart', 'getPieCoordinates');
resolveImport('ScatterChart', 'ScatterChart');
resolveImport('ScatterChart', 'ScatterPlot');
resolveImport('ScatterChart', 'Scatter');
resolveImport('SparkLineChart', 'SparkLineChart');
resolveImport('Gauge', 'useGaugeState');
resolveImport('Gauge', 'Gauge');
resolveImport('Gauge', 'GaugeContainer');
resolveImport('Gauge', 'GaugeValueText');
resolveImport('Gauge', 'GaugeValueArc');
resolveImport('Gauge', 'GaugeReferenceArc');
resolveImport('Gauge', 'gaugeClasses');
resolveImport('Gauge', 'getGaugeUtilityClass');
resolveImport('ChartContainer', 'ChartContainer');
resolveImport('ChartsSurface', 'ChartsSurface');
resolveImport('ResponsiveChartContainer', 'ResponsiveChartContainer');