export const PACKAGE = { name: '@mui/x-tree-view-pro', version: '7.23.0' };
export * from '@mui/x-tree-view-pro';
export { unstable_resetCleanupTracking as resetCleanupTracking } from '@mui/x-tree-view-pro';
export { unstable_useTreeItem2 as useTreeItem2 } from '@mui/x-tree-view-pro';
export * as 'internals' from '@mui/x-tree-view-pro/internals/index.js';
export * as 'themeAugmentation' from '@mui/x-tree-view-pro/themeAugmentation/index.js';
import * as xTreeViewPro from '@mui/x-tree-view-pro';

            const resolveImport = (itemName, subItemName) => {
                const item = xTreeViewPro[itemName];
                const subItem = xTreeViewPro[subItemName];
                if (item && subItem) {
                    try {
                        if (!item.hasOwnProperty(subItemName)) {
                            item[subItemName] = subItem;

                            const prefixes = [ 'internal_', 'unstable_' ];
                            for (let prefix of prefixes) {
                                if (subItemName.startsWith(prefix)) {
                                    const newName = subItemName.slice(prefix.length);
                                    item[newName] = subItem;
                                }
                            }
                        }
                    } catch {}
                }
            };
        
resolveImport('RichTreeViewPro', 'RichTreeViewPro');
resolveImport('RichTreeViewPro', 'RichTreeViewProRoot');
resolveImport('RichTreeViewPro', 'getRichTreeViewProUtilityClass');
resolveImport('RichTreeViewPro', 'richTreeViewProClasses');