export const PACKAGE = { name: '@mui/x-charts-pro', version: '7.23.0' };
export * from '@mui/x-charts-pro';
export { unstable_useBarSeries as useBarSeries } from '@mui/x-charts-pro';
export { unstable_useLineSeries as useLineSeries } from '@mui/x-charts-pro';
export { unstable_usePieSeries as usePieSeries } from '@mui/x-charts-pro';
export { unstable_useScatterSeries as useScatterSeries } from '@mui/x-charts-pro';
export { unstable_useSeries as useSeries } from '@mui/x-charts-pro';
export * as 'context' from '@mui/x-charts-pro/context/index.js';
export * as 'hooks' from '@mui/x-charts-pro/hooks/index.js';
export * as 'models' from '@mui/x-charts-pro/models/index.js';
export * as 'themeAugmentation' from '@mui/x-charts-pro/themeAugmentation/index.js';
export * as 'typeOverloads' from '@mui/x-charts-pro/typeOverloads/index.js';
import * as xChartsPro from '@mui/x-charts-pro';

            const resolveImport = (itemName, subItemName) => {
                const item = xChartsPro[itemName];
                const subItem = xChartsPro[subItemName];
                if (item && subItem) {
                    try {
                        if (!item.hasOwnProperty(subItemName)) {
                            item[subItemName] = subItem;

                            const prefixes = [ 'internal_', 'unstable_' ];
                            for (let prefix of prefixes) {
                                if (subItemName.startsWith(prefix)) {
                                    const newName = subItemName.slice(prefix.length);
                                    item[newName] = subItem;
                                }
                            }
                        }
                    } catch {}
                }
            };
        
resolveImport('Heatmap', 'Heatmap');
resolveImport('Heatmap', 'HeatmapPlot');
resolveImport('Heatmap', 'DefaultHeatmapTooltip');
resolveImport('Heatmap', 'getHeatmapUtilityClass');
resolveImport('Heatmap', 'heatmapClasses');
resolveImport('ResponsiveChartContainerPro', 'ResponsiveChartContainerPro');
resolveImport('ChartContainerPro', 'ChartContainerPro');
resolveImport('ScatterChartPro', 'ScatterChartPro');
resolveImport('BarChartPro', 'BarChartPro');
resolveImport('LineChartPro', 'LineChartPro');