export const PACKAGE = { name: '@mui/x-tree-view', version: '7.23.0' };
export * from '@mui/x-tree-view';
export { unstable_resetCleanupTracking as resetCleanupTracking } from '@mui/x-tree-view';
export { unstable_useTreeItem2 as useTreeItem2 } from '@mui/x-tree-view';
export * as 'hooks' from '@mui/x-tree-view/hooks/index.js';
export * as 'icons' from '@mui/x-tree-view/icons/index.js';
export * as 'internals' from '@mui/x-tree-view/internals/index.js';
export * as 'models' from '@mui/x-tree-view/models/index.js';
export * as 'themeAugmentation' from '@mui/x-tree-view/themeAugmentation/index.js';
import * as xTreeView from '@mui/x-tree-view';

            const resolveImport = (itemName, subItemName) => {
                const item = xTreeView[itemName];
                const subItem = xTreeView[subItemName];
                if (item && subItem) {
                    try {
                        if (!item.hasOwnProperty(subItemName)) {
                            item[subItemName] = subItem;

                            const prefixes = [ 'internal_', 'unstable_' ];
                            for (let prefix of prefixes) {
                                if (subItemName.startsWith(prefix)) {
                                    const newName = subItemName.slice(prefix.length);
                                    item[newName] = subItem;
                                }
                            }
                        }
                    } catch {}
                }
            };
        
resolveImport('TreeView', 'TreeView');
resolveImport('TreeView', 'getTreeViewUtilityClass');
resolveImport('TreeView', 'treeViewClasses');
resolveImport('SimpleTreeView', 'SimpleTreeView');
resolveImport('SimpleTreeView', 'SimpleTreeViewRoot');
resolveImport('SimpleTreeView', 'getSimpleTreeViewUtilityClass');
resolveImport('SimpleTreeView', 'simpleTreeViewClasses');
resolveImport('RichTreeView', 'RICH_TREE_VIEW_PLUGINS');
resolveImport('RichTreeView', 'RichTreeView');
resolveImport('RichTreeView', 'RichTreeViewRoot');
resolveImport('RichTreeView', 'getRichTreeViewUtilityClass');
resolveImport('RichTreeView', 'richTreeViewClasses');
resolveImport('TreeItem', 'TreeItem');
resolveImport('TreeItem', 'TreeItemContent');
resolveImport('TreeItem', 'getTreeItemUtilityClass');
resolveImport('TreeItem', 'treeItemClasses');
resolveImport('TreeItem', 'useTreeItemState');
resolveImport('TreeItem2', 'TreeItem2');
resolveImport('TreeItem2', 'TreeItem2Checkbox');
resolveImport('TreeItem2', 'TreeItem2Content');
resolveImport('TreeItem2', 'TreeItem2GroupTransition');
resolveImport('TreeItem2', 'TreeItem2IconContainer');
resolveImport('TreeItem2', 'TreeItem2Label');
resolveImport('TreeItem2', 'TreeItem2Root');
resolveImport('unstable_useTreeItem2', 'unstable_useTreeItem2');
resolveImport('unstable_useTreeItem2', 'useTreeItem2');
resolveImport('useTreeItem2', 'unstable_useTreeItem2');
resolveImport('useTreeItem2', 'useTreeItem2');
resolveImport('TreeItem2Icon', 'TreeItem2Icon');
resolveImport('TreeItem2Provider', 'TreeItem2Provider');
resolveImport('TreeItem2DragAndDropOverlay', 'TreeItem2DragAndDropOverlay');
resolveImport('TreeItem2LabelInput', 'TreeItem2LabelInput');