export const PACKAGE = { name: '@mui/x-date-pickers', version: '7.23.0' };
export * from '@mui/x-date-pickers';
export { unstable_useDateField as useDateField } from '@mui/x-date-pickers';
export { unstable_useTimeField as useTimeField } from '@mui/x-date-pickers';
export { unstable_useDateTimeField as useDateTimeField } from '@mui/x-date-pickers';
export * as 'AdapterDateFnsBase' from '@mui/x-date-pickers/AdapterDateFnsBase/index.js';
export * as 'AdapterDayjs' from '@mui/x-date-pickers/AdapterDayjs/index.js';
export * as 'PickersSectionList' from '@mui/x-date-pickers/PickersSectionList/index.js';
export * as 'dateViewRenderers' from '@mui/x-date-pickers/dateViewRenderers/index.js';
export * as 'hooks' from '@mui/x-date-pickers/hooks/index.js';
export * as 'icons' from '@mui/x-date-pickers/icons/index.js';
export * as 'internals' from '@mui/x-date-pickers/internals/index.js';
export * as 'locales' from '@mui/x-date-pickers/locales/index.js';
export * as 'models' from '@mui/x-date-pickers/models/index.js';
export * as 'themeAugmentation' from '@mui/x-date-pickers/themeAugmentation/index.js';
export * as 'timeViewRenderers' from '@mui/x-date-pickers/timeViewRenderers/index.js';
export * as 'validation' from '@mui/x-date-pickers/validation/index.js';
import * as xDatePickers from '@mui/x-date-pickers';

            const resolveImport = (itemName, subItemName) => {
                const item = xDatePickers[itemName];
                const subItem = xDatePickers[subItemName];
                if (item && subItem) {
                    try {
                        if (!item.hasOwnProperty(subItemName)) {
                            item[subItemName] = subItem;

                            const prefixes = [ 'internal_', 'unstable_' ];
                            for (let prefix of prefixes) {
                                if (subItemName.startsWith(prefix)) {
                                    const newName = subItemName.slice(prefix.length);
                                    item[newName] = subItem;
                                }
                            }
                        }
                    } catch {}
                }
            };
        
resolveImport('TimeClock', 'TimeClock');
resolveImport('TimeClock', 'clockClasses');
resolveImport('TimeClock', 'clockNumberClasses');
resolveImport('TimeClock', 'clockPointerClasses');
resolveImport('TimeClock', 'getTimeClockUtilityClass');
resolveImport('TimeClock', 'timeClockClasses');
resolveImport('DigitalClock', 'DigitalClock');
resolveImport('DigitalClock', 'digitalClockClasses');
resolveImport('DigitalClock', 'getDigitalClockUtilityClass');
resolveImport('MultiSectionDigitalClock', 'MultiSectionDigitalClock');
resolveImport('MultiSectionDigitalClock', 'getMultiSectionDigitalClockUtilityClass');
resolveImport('MultiSectionDigitalClock', 'multiSectionDigitalClockClasses');
resolveImport('MultiSectionDigitalClock', 'multiSectionDigitalClockSectionClasses');
resolveImport('LocalizationProvider', 'LocalizationProvider');
resolveImport('LocalizationProvider', 'MuiPickersAdapterContext');
resolveImport('PickersDay', 'PickersDay');
resolveImport('PickersDay', 'getPickersDayUtilityClass');
resolveImport('PickersDay', 'pickersDayClasses');
resolveImport('DateField', 'DateField');
resolveImport('DateField', 'unstable_useDateField');
resolveImport('TimeField', 'TimeField');
resolveImport('TimeField', 'unstable_useTimeField');
resolveImport('DateTimeField', 'DateTimeField');
resolveImport('DateTimeField', 'unstable_useDateTimeField');
resolveImport('DateCalendar', 'DateCalendar');
resolveImport('DateCalendar', 'dateCalendarClasses');
resolveImport('DateCalendar', 'dayCalendarClasses');
resolveImport('DateCalendar', 'getDateCalendarUtilityClass');
resolveImport('DateCalendar', 'pickersFadeTransitionGroupClasses');
resolveImport('DateCalendar', 'pickersSlideTransitionClasses');
resolveImport('MonthCalendar', 'MonthCalendar');
resolveImport('MonthCalendar', 'getMonthCalendarUtilityClass');
resolveImport('MonthCalendar', 'monthCalendarClasses');
resolveImport('MonthCalendar', 'pickersMonthClasses');
resolveImport('YearCalendar', 'YearCalendar');
resolveImport('YearCalendar', 'getYearCalendarUtilityClass');
resolveImport('YearCalendar', 'pickersYearClasses');
resolveImport('YearCalendar', 'yearCalendarClasses');
resolveImport('DayCalendarSkeleton', 'DayCalendarSkeleton');
resolveImport('DayCalendarSkeleton', 'dayCalendarSkeletonClasses');
resolveImport('DayCalendarSkeleton', 'getDayCalendarSkeletonUtilityClass');
resolveImport('DatePicker', 'DatePicker');
resolveImport('DatePicker', 'DatePickerToolbar');
resolveImport('DatePicker', 'datePickerToolbarClasses');
resolveImport('DesktopDatePicker', 'DesktopDatePicker');
resolveImport('MobileDatePicker', 'MobileDatePicker');
resolveImport('StaticDatePicker', 'StaticDatePicker');
resolveImport('TimePicker', 'TimePicker');
resolveImport('TimePicker', 'TimePickerToolbar');
resolveImport('TimePicker', 'timePickerToolbarClasses');
resolveImport('DesktopTimePicker', 'DesktopTimePicker');
resolveImport('MobileTimePicker', 'MobileTimePicker');
resolveImport('StaticTimePicker', 'StaticTimePicker');
resolveImport('DateTimePicker', 'DateTimePicker');
resolveImport('DateTimePicker', 'DateTimePickerTabs');
resolveImport('DateTimePicker', 'DateTimePickerToolbar');
resolveImport('DateTimePicker', 'dateTimePickerTabsClasses');
resolveImport('DateTimePicker', 'dateTimePickerToolbarClasses');
resolveImport('DesktopDateTimePicker', 'DesktopDateTimePicker');
resolveImport('DesktopDateTimePicker', 'DesktopDateTimePickerLayout');
resolveImport('MobileDateTimePicker', 'MobileDateTimePicker');
resolveImport('StaticDateTimePicker', 'StaticDateTimePicker');
resolveImport('PickersLayout', 'PickersLayout');
resolveImport('PickersLayout', 'PickersLayoutContentWrapper');
resolveImport('PickersLayout', 'PickersLayoutRoot');
resolveImport('PickersLayout', 'pickersLayoutClasses');
resolveImport('PickersLayout', 'usePickerLayout');
resolveImport('PickersActionBar', 'PickersActionBar');
resolveImport('PickersShortcuts', 'PickersShortcuts');
resolveImport('PickersCalendarHeader', 'PickersCalendarHeader');
resolveImport('PickersCalendarHeader', 'pickersCalendarHeaderClasses');
resolveImport('PickersTextField', 'PickersTextField');
resolveImport('PickersTextField', 'getPickersTextFieldUtilityClass');
resolveImport('PickersTextField', 'pickersTextFieldClasses');
resolveImport('PickersTextField', 'PickersInput');
resolveImport('PickersTextField', 'getPickersInputUtilityClass');
resolveImport('PickersTextField', 'pickersInputClasses');
resolveImport('PickersTextField', 'PickersFilledInput');
resolveImport('PickersTextField', 'getPickersFilledInputUtilityClass');
resolveImport('PickersTextField', 'pickersFilledInputClasses');
resolveImport('PickersTextField', 'PickersOutlinedInput');
resolveImport('PickersTextField', 'getPickersOutlinedInputUtilityClass');
resolveImport('PickersTextField', 'pickersOutlinedInputClasses');
resolveImport('PickersTextField', 'PickersInputBase');
resolveImport('PickersTextField', 'getPickersInputBaseUtilityClass');
resolveImport('PickersTextField', 'pickersInputBaseClasses');